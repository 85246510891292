import { FC } from "react"
import type { ProductVariant } from "shopify-storefront-api-typings"

import { useCustomer } from "@hooks/index"
import styled from "styled-components"

interface Props {
  variant: ProductVariant
  price?: number | undefined
  discountPrice?: number | undefined
  isRange?: boolean
  isVariantSelected?: any
}
export const Price: FC<Props> = ({
  price: lowestNonDiscountPrice,
  variant,
  discountPrice,
  isRange,
  isVariantSelected,
}) => {
  const { getPricePair, formatCurrencyPrice } = useCustomer()
  const pricePair = getPricePair(variant)
  const price = Number(lowestNonDiscountPrice || pricePair.price.amount)
  console.log(discountPrice, price)
  return (
    <>
      {isVariantSelected ? (
        discountPrice && discountPrice !== price ? (
          <>
            <span style={{ fontWeight: 500 }}>
              {isRange ? `${formatCurrencyPrice(+price)}+ ` : `${formatCurrencyPrice(+price)} `}
            </span>
            <s>
              {isRange
                ? `${formatCurrencyPrice(discountPrice)}+`
                : `${formatCurrencyPrice(discountPrice)}`}
            </s>
          </>
        ) : (
          <span>{isRange ? `${formatCurrencyPrice(price)}+` : formatCurrencyPrice(price)}</span>
        )
      ) : discountPrice && discountPrice !== price ? (
        <>
          <span style={{ fontWeight: 500 }}>
            {isRange
              ? `${formatCurrencyPrice(+discountPrice)}+ `
              : `${formatCurrencyPrice(+discountPrice)} `}
          </span>
          <s>{isRange ? `${formatCurrencyPrice(price)}+` : `${formatCurrencyPrice(price)}`}</s>
        </>
      ) : (
        <span>{isRange ? `${formatCurrencyPrice(price)}+` : formatCurrencyPrice(price)}</span>
      )}
    </>
  )
}
