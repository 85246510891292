import axios from "axios"
import retry from "async-retry"

import type {
  CheckoutLineItemUpdateInput,
  CheckoutAttributesUpdateV2Input,
  CheckoutDiscountCodeApplyV2Payload,
  Checkout,
  CheckoutCreateInput,
  CheckoutCreatePayload,
  CheckoutLineItemsAddPayload,
  CheckoutLineItemsRemovePayload,
  CheckoutLineItemsUpdatePayload,
  CheckoutAttributesUpdateV2Payload,
  Product,
} from "shopify-storefront-api-typings"
import { shopifyConfig, headersAdmin, preparePayload, regionToFulfillmentId } from "@lib/config"
import {
  PRODUCT_BY_HANDLE_QUERY_LOCALIZED,
  PRODUCTS_BY_HANDLES_QUERY_LOCALIZED,
  PRODUCT_RECOMMENDATIONS_QUERY,
  CHECKOUT_BY_ID_QUERY,
  GET_VARIANTS_INVENTORY,
  PRODUCTS_INVENTORY_BY_HANDLES_QUERY_LOCALIZED,
} from "@lib/fragments"
import {
  CHECKOUT_CREATE_MUTATION,
  CHECKOUT_LINE_ITEMS_ADD,
  CHECKOUT_LINE_ITEMS_REMOVE,
  CHECKOUT_LINE_ITEMS_UPDATE,
  CHECKOUT_ATTRIBUTES_UPDATE_V2,
  CHECKOUT_DISCOUNT_CODE_APPLY_V2,
} from "@lib/mutations"
import { ContentfulProductWithShopifyData } from "./types"
import { useRouter } from "next/router"
import { sleep } from "./utils"

let isServer = typeof window === "undefined" ? false : true

async function sendShopifyAdminRequest(payload: object): Promise<any> {
  return retry(
    async (bail: Function, attempt: number) => {
      if (attempt > 1) console.log("re-attempting shopify request...", attempt)
      return axios({
        url: `https://secure.${process.env.NEXT_PUBLIC_SHOPIFY_STORE}.com/admin/api/2021-10/graphql.json`,
        method: "POST",
        headers: headersAdmin,
        data: JSON.stringify(payload),
      }).then(res => res.data.data)
    },
    { retries: isServer ? 10 : 5 }
  )
}

async function sendShopifyRequest(payload: object): Promise<any> {
  const retry: any = async (payload: object, attempt: number = 1) => {
    if (attempt > 1) console.log("re-attempting shopify request...", attempt)
    const res = await fetch(
      `https://secure.${process.env.NEXT_PUBLIC_SHOPIFY_STORE}.com/api/2021-10/graphql.json`,
      {
        method: "POST",
        /* @ts-ignore */
        headers: shopifyConfig,
        body: JSON.stringify(payload),
      }
    )
    const data = await res.json()
    if (!data.data) {
      if (data.errors.some((error: any) => error.message.toLowerCase() == "throttled")) {
        await sleep(attempt * (isServer ? 1000 : 500))
        return retry(payload, attempt + 1)
      }
    }
    return data.data
  }
  const data = await retry(payload)
  return data
}

export const getProductVariantsMetafields = async (ids: string[]) => {
  const payload = preparePayload(GET_VARIANTS_INVENTORY, {
    ids,
  })
  const { nodes } = await sendShopifyRequest(payload)
  return nodes
}

export const getAdminProductByHandle = async (handle: string): Promise<Product | undefined> => {
  const payload = preparePayload(PRODUCT_BY_HANDLE_QUERY_LOCALIZED("en-US"), {
    handle,
  })
  const result = await sendShopifyAdminRequest(payload)
  if (!result) {
    console.log("getProductAdmin", result)
  }
  /* NOTE: result is sometimes undefined but this expects to return a Product. The actual type should be Product | undefined and be handled accordingly */
  return (result || {}).product
}

export const checkProductDisplayFlags = async (
  handle: string,
  locale: string | undefined
): Promise<any> => {
  const payload = preparePayload(PRODUCT_BY_HANDLE_QUERY_LOCALIZED(locale), {
    handle,
  })
  const result = await sendShopifyRequest(payload)
  if (!result) {
    console.log(result)
    return { hideFromSite: false, hideFromCollections: false }
  }
  const { hideFromSite, hideFromCollections } = JSON.parse(result?.product?.contentfulData?.value)
  return { hideFromSite, hideFromCollections }
}

export const getShopifyProductWithContentfulData = async (
  handle: string,
  locale: string | undefined
): Promise<any> => {
  const payload = preparePayload(PRODUCT_BY_HANDLE_QUERY_LOCALIZED(locale), {
    handle,
  })
  const result = await sendShopifyRequest(payload)
  if (!result) {
    console.log(result)
  }
  /* NOTE: result is sometimes undefined but this expects to return a Product. The actual type should be Product | undefined and be handled accordingly */
  return (result || {}).product
}

export const getProductsFlagsVisibilityState = async (
  handles: string,
  locale: string | undefined
): Promise<any> => {
  const payload = preparePayload(PRODUCTS_BY_HANDLES_QUERY_LOCALIZED(locale, handles), {})
  const result = await sendShopifyRequest(payload)
  if (!result) {
    console.log(result)
    return { hideFromSite: false, hideFromCollections: false }
  }
  const productsArray = []
  for (let key in result) {
    productsArray.push({ ...result[key], ...JSON.parse(result[key]?.contentfulData?.value) })
  }
  return productsArray
}

export const getProductByHandle = async (
  handle: string,
  locale?: string
): Promise<Product | undefined> => {
  const payload = preparePayload(PRODUCT_BY_HANDLE_QUERY_LOCALIZED(locale), {
    handle,
  })
  const result = await sendShopifyRequest(payload)
  if (!result) {
    console.log("getProduct", result)
  }
  /* NOTE: result is sometimes undefined but this expects to return a Product. The actual type should be Product | undefined and be handled accordingly */
  return (result || {}).product
}

export const getShopifyProductsInventoryByHandles = async (
  handles: string[],
  locale: string | undefined
): Promise<any> => {
  const payload = preparePayload(PRODUCTS_INVENTORY_BY_HANDLES_QUERY_LOCALIZED(locale, handles), {})
  const result = await sendShopifyRequest(payload)
  if (!result) {
    return []
  }
  const productsArray = []
  const returnInventoryByLocale = (metafields: any) => {
    const { edges } = metafields
    return edges.find((edge: any) =>
      edge.node.key.includes(`location:${regionToFulfillmentId(locale || "en-US")}`)
    ).node.value
  }
  for (let key in result) {
    productsArray.push({
      key: key.replaceAll("_", "-"),
      inventory: returnInventoryByLocale(result[key].variants.edges[0].node.metafields),
    })
  }
  return productsArray
}

export const getProductRecommendations = async (productId: string): Promise<Array<Product>> => {
  const payload = preparePayload(PRODUCT_RECOMMENDATIONS_QUERY, {
    productId,
  })
  const { productRecommendations } = await sendShopifyRequest(payload)
  return productRecommendations
}

export const getCheckout = async (id: string): Promise<Checkout> => {
  const payload = preparePayload(CHECKOUT_BY_ID_QUERY, {
    id,
  })
  const { checkout } = await sendShopifyRequest(payload)
  return checkout
}

type CheckoutCreateNewInput = CheckoutCreateInput & { buyerIdentity?: any }
type CheckoutCreateNewPayload = CheckoutCreatePayload & { checkout: object }

export const createCheckout = async (
  countryCode: string,
  checkoutCreateInput?: CheckoutCreateNewInput
): Promise<CheckoutCreateNewPayload> => {
  const input = checkoutCreateInput || {}
  const payload = preparePayload(CHECKOUT_CREATE_MUTATION(countryCode), {
    input,
  })
  let { checkoutCreate } = await sendShopifyRequest(payload)
  return checkoutCreate
}

export const checkoutLineItemsAdd = async ({
  lineItems,
  checkoutId,
}: {
  lineItems: { quantity: number; variantId: string }[]
  checkoutId: string
}): Promise<CheckoutLineItemsAddPayload> => {
  const payload = preparePayload(CHECKOUT_LINE_ITEMS_ADD, {
    lineItems,
    checkoutId,
  })
  const { checkoutLineItemsAdd } = await sendShopifyRequest(payload)
  return checkoutLineItemsAdd
}

export const checkoutLineItemsRemove = async ({
  lineItemIds,
  checkoutId,
}: {
  checkoutId: string
  lineItemIds: string[]
}): Promise<CheckoutLineItemsRemovePayload> => {
  const payload = preparePayload(CHECKOUT_LINE_ITEMS_REMOVE, {
    checkoutId,
    lineItemIds,
  })
  const { checkoutLineItemsRemove } = await sendShopifyRequest(payload)
  return checkoutLineItemsRemove
}

export const checkoutLineItemsUpdate = async ({
  checkoutId,
  lineItems,
}: {
  checkoutId: string
  lineItems: CheckoutLineItemUpdateInput[]
}): Promise<CheckoutLineItemsUpdatePayload> => {
  const payload = preparePayload(CHECKOUT_LINE_ITEMS_UPDATE, {
    checkoutId,
    lineItems,
  })
  const { checkoutLineItemsUpdate } = await sendShopifyRequest(payload)
  return checkoutLineItemsUpdate
}

export const checkoutAttributesUpdateV2 = async ({
  checkoutId,
  input,
}: {
  checkoutId: string
  input: CheckoutAttributesUpdateV2Input
}): Promise<CheckoutAttributesUpdateV2Payload> => {
  const payload = preparePayload(CHECKOUT_ATTRIBUTES_UPDATE_V2, {
    checkoutId,
    input,
  })
  const { checkoutAttributesUpdateV2 } = await sendShopifyRequest(payload)
  return checkoutAttributesUpdateV2
}

export const checkoutDiscountCodeApplyV2 = async ({
  checkoutId,
  discountCode,
}: {
  checkoutId: string
  discountCode: CheckoutDiscountCodeApplyV2Payload
}): Promise<CheckoutDiscountCodeApplyV2Payload> => {
  const payload = preparePayload(CHECKOUT_DISCOUNT_CODE_APPLY_V2, {
    checkoutId,
    discountCode,
  })
  const { checkoutDiscountCodeApplyV2 } = await sendShopifyRequest(payload)
  return checkoutDiscountCodeApplyV2
}
